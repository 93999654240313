<template>
  <div class="error-page">
    <b-container>
      <div class="errorContainer mx-auto">
        <h1 class="errorCode">404</h1>
        <p class="errorInfo">
          Oops, the page you are looking for does not exist!
        </p>
        <p class="errorHelp mb-3">
          Please go back and try again.
        </p>
        <p>
          <router-link :to="{ name: 'home' }">
            <b-button class="mr-xs" variant="default">
              back
            </b-button>
          </router-link>
        </p>
      </div>
      <footer class="pageFooter">{{ year }} &copy; Goaltip GmbH</footer>
    </b-container>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'Page404',
  computed: {
    year() {
      return dayjs().format('YYYY');
    },
  },
};
</script>

<style src="./Error.scss" lang="scss" scoped />
